import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (expanded) {
      const timer = setTimeout(() => setExpanded(false), 7000); // 5 seconds idle time
      return () => clearTimeout(timer);
    }
  }, [expanded]);

  return (
    <>
      <div className="header">
        <Navbar expanded={expanded} onToggle={() => setExpanded(!expanded)} expand="lg">
          <Navbar.Brand as={Link} to="/">
            <img className="logo" align="left" src="/images/logo.jpeg" alt="TechConnect Foundation" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarNav" />
          <Navbar.Collapse id="navbarNav" className='justify-content-end'>
            <Nav>
              <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/aboutus" onClick={() => setExpanded(false)}>
                About Us
              </Nav.Link>
              <Nav.Link as={Link} to="/programs" onClick={() => setExpanded(false)}>
                Programs
              </Nav.Link>
              <Nav.Link as={Link} to="/sucessstories" onClick={() => setExpanded(false)}>
                Success Stories
              </Nav.Link>
              <Nav.Link as={Link} to="/contactus" onClick={() => setExpanded(false)}>
                Contact Us
              </Nav.Link>
              <Nav.Link as={Link} to="/supportus" onClick={() => setExpanded(false)} >
                <p className="support-us-link">Support Us</p>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className='row'>
        <div className="border-below"></div>
      </div>
    </>
  );
}

export default Header;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const SupportUs = () => {
  return (
    <Container className="mt-5">
      {/* Donate Section */}
      <Row className="mb-4">
        <Col>
          <h2 className="poppins-bold">Donate</h2>
          <p className="poppins-regular">
            Your support helps us continue providing computers and resources to underprivileged communities. Together, we can bridge the digital divide and create opportunities for everyone. Thank you for considering donating to our cause!
          </p>
          <p className="poppins-regular">
            We're excited to share that TechConnect Foundation is officially recognized as a nonprofit under <strong>Section 501(c)(3)</strong> of the Internal Revenue Code. This means that your donations are tax-deductible! Whether it’s a bequest, transfer, or gift, you can help make a real difference in someone's life, and your contribution is fully deductible under <strong>IRC Section 170</strong>.
          </p>
          <p>
            <iframe 
              src="https://donorbox.org/embed/bridging-the-technological-divide?language=en-us" 
              name="donorbox" 
              allowpaymentrequest="allowpaymentrequest" 
              className='custom-donor' 
              seamless="seamless" 
              frameBorder="0" 
              scrolling="no" 
              allow="payment"
            ></iframe>
          </p>
        </Col>
      </Row>

      {/* Volunteer Section */}
      <Row className="mb-4">
        <Col>
          <h2 className="poppins-bold">Volunteer</h2>
          <p className="poppins-regular">
            Want to make a real difference? Whether you’re into tech or just love helping others, we’d love to have you volunteer with us! You can help by refurbishing computers or even mentoring people through our online courses. Your time and skills can really make a big impact!
          </p>
        </Col>
      </Row>

      {/* Partner with Us Section */}
      <Row className="mb-4">
        <Col>
          <h2 className="poppins-bold">Partner with Us</h2>
          <p className="poppins-regular">
            We’re always looking for businesses, organizations, or communities that share our vision to work with. Partnering with us means we can expand our reach, improve our programs, and make an even bigger impact together. Let’s team up and create some real change!
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default SupportUs;

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { TwitterTweetEmbed } from 'react-twitter-embed';

function Main() {
  return (
    <>
      {/* First Section: Image on the Left, Text on the Right */}
      <div style={{ backgroundColor: '#33acff', padding: '50px 0' }}>
        <Row className="mb-5">
          {/* Left Column: Image */}
          <Col md={4} className="d-flex justify-content-center align-items-center">
            <img
              src="images/tcf_2.png"
              alt="TechConnect Foundation"
              style={{ maxWidth: '400px', height: 'auto', padding: '10px' }}
            />
          </Col>

          {/* Right Column: Featured Content */}
          <Col md={6} className="d-flex flex-column justify-content-center" style={{ padding: '0 20px' }} >
            <h1 className="poppins-bold">Making Computer Ownership Possible For Everyone</h1>
            <p className="poppins-regular">
              TechConnect is committed to providing technology and educational resources to underserved communities in Illinois. Join us in closing the digital divide.
            </p>
          </Col>
        </Row>
      </div>

      {/* Second Section: Underrepresented Households Statistics */}
      <div style={{ backgroundColor: '#61d0e8', padding: '50px 0' }}>
        <Row className="align-items-center">
          {/* Left Column: Centered Title and Text Block */}
          <Col 
            xs={12} 
            sm={12} 
            md={6} 
            className="d-flex flex-column justify-content-center align-items-start"
            style={{ padding: '0 70px' }} // Adjusted padding to create space on the left
          >
            <h2 
              className="poppins-bold"
              style={{ width: '100%', marginBottom: '15px', textAlign: 'left' }}
            >
              The Digital Divide
            </h2>
            <p className="poppins-regular">
              1 in 3 underrepresented households lack access to a computer. Device ownership is crucial for digital equity and access to opportunities, yet significant technology gaps persist nationwide.
            </p>
          </Col>

          {/* Right Column: Embedded Twitter Post */}
          <Col xs={12} sm={12} md={6} className="d-flex justify-content-left" style={{ padding: '0 80px' }}>
            <TwitterTweetEmbed tweetId="1859378692914328030" />
          </Col>
        </Row>
      </div>

      {/* Third Section: Text to the Right with Image on the Left */}
      <div style={{ backgroundColor: '#dee2e6', padding: '50px 0' }}>
        <Row className="mb-5 align-items-center">
          {/* Left Column: Image */}
          <Col md={6} className="d-flex justify-content-center">
            <img
              src="images/digitaldividepic.jpg"
              alt="Community Support"
              style={{ maxWidth: '400px', height: 'auto', padding: '10px' }}
            />
          </Col>

          {/* Right Column: Text */}
          <Col md={6} className="d-flex flex-column justify-content-center align-items-start">
            <h2 className="poppins-bold" style={{ padding: '0 20px', marginBottom: '15px' }}>
              Digital Literacy Matters
            </h2>
            <p className="poppins-regular" style={{ padding: '0 20px', marginBottom: '15px'}}>
              52 million adults in the U.S. are not digitally literate. This lack of skills hinders education, employment, and quality of life for millions.
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Main;